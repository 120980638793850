import { AuthProvider } from "react-admin";

import { getSessionToken, login, tokenStorageKey } from "@utils";

export const authProvider: AuthProvider = {
  login: async (params: any) => {
    const result = await login(params);

    if (!result || 'error' in result) {
      throw new Error(result?.message || 'Internal server error');
    }

    localStorage.setItem(tokenStorageKey, result.token);
  },
  checkAuth: async () => {
    const sessionToken = getSessionToken();

    if (!sessionToken) {
      return Promise.reject({ redirectTo: '/login' });
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(tokenStorageKey);
      return Promise.reject({ redirectTo: "/login" });
    }

    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem(tokenStorageKey);
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const sessionToken = localStorage.getItem(tokenStorageKey) || "";
      return Promise.resolve({ id: sessionToken });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

import { Typography } from '@mui/material';
import { FC, memo } from 'react';

interface CustomFormFieldLabelProps {
  text: string,
}

export const CustomFormFieldLabel: FC<CustomFormFieldLabelProps> = memo(({
  text,
}) => {
  return (
    <Typography
      variant="h6"
      component="div"
    >
      {text}
    </Typography>
  );
});

import { FC } from "react";

import { CustomFormContent } from '@customForms/components';
import { CustomBooleanInput, CustomSelectInput, CustomTextInput } from '@customForms/inputs';

import { FormWrapper } from "@components/common/FormWrapper";

import { productFromChoices, productSources } from "@constants";
import { CollectionRus } from "@enum";
import { CustomFormProps } from "@interfaces/validator";
import { Product } from "@interfaces/entities";
import { productDataProvider } from "@dataProviders/productDataProvider";
import { productValidators } from "@utils";

import { History } from './components';

const defaultFormData: Partial<Product> = {
  name: '',
  userId: '',
};

export const ProductForm: FC<CustomFormProps> = (props) => {
  const { id } = props;

  return (
    <FormWrapper
      {...props}
      redirectCollection={CollectionRus.Product}
      defaultFormData={defaultFormData}
      dataProvider={productDataProvider}
      validators={productValidators}
    >
      <CustomFormContent>
        <CustomTextInput
          label="Имя продукта"
          fieldKey={productSources.name}
          fullWidth
          required
        />

        <CustomTextInput
          label="Id пользователя"
          fieldKey={productSources.userId}
          required
        />

        <CustomSelectInput
          label="From"
          choices={productFromChoices}
          optionText="label"
          optionValue="value"
          fieldKey={productSources.from}
        />

        <CustomTextInput
          label="telegram id"
          fieldKey={productSources.tgId}
        />

        {!!id && (
          <CustomBooleanInput
            label="isDeleted"
            fieldKey={productSources.isDeleted}
            disabled
          />
        )}

        {!!id && (
          <CustomTextInput
            label="Дни премиума"
            fieldKey={productSources.remainingDaysOfPremium}
            disabled
            type="number"
          />
        )}

        {!!id && (
          <History productId={id} />
        )}
      </CustomFormContent>
    </FormWrapper>
  );
};

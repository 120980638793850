import { FC, memo } from 'react';
import { Card, CardProps } from '@mui/material';

interface CustomFormContainerProps extends CardProps {
  flat?: boolean,
}

export const CustomFormContainer: FC<CustomFormContainerProps> = memo(({
  flat = false,
  children,
  ...otherProps
}) => {
  const elevation = Number(!flat);

  return (
    <Card
      sx={style}
      {...otherProps}
      elevation={elevation}
    >
      {children}
    </Card>
  );
});

const style = {
  overflowY: 'auto',
};

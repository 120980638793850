import { useState, PropsWithChildren, useRef } from 'react';

import { useNotify, useRedirect } from 'react-admin';

import { CustomFormContainer } from '@customForms/components';
import { CustomFormProvider } from '@customForms/context';

import useAsyncEffect from '@hooks/useAsyncEffect';

// import { CustomDataProviderType } from '@interfaces/provider';
import { Actions } from './components';
// import { NewCustomDataProvider } from '@interfaces/new-provider';
import { CollectionRus } from '@enum';
import { CustomFormAfterSaveCallback, CustomFormValidators } from '@interfaces/validator';

type FormWrapperProps = PropsWithChildren<{
  /** нужен для выполнения запросов */
  dataProvider: any //CustomDataProviderType<any> | NewCustomDataProvider<any>;
  /** объект валидаций */
  validators: CustomFormValidators;
  /** ссылка для перехода после успешного сохранения */
  redirectCollection?: CollectionRus;
  /** id - для запроса данных на редактирование */
  id?: string;
  /** убирает тень у карточки формы */
  flat?: boolean;
  /** устанавливает начальное состояние формы */
  defaultFormData?: any;
  /**
   * Перезаписать логику после сохранения.
   * По умолчанию переходит на redirectCollection/ничего не делает
   */
  afterSave?: CustomFormAfterSaveCallback;
}>;

export const FormWrapper = ({
  id,
  defaultFormData = {},
  flat = false,
  dataProvider,
  validators,
  redirectCollection,
  afterSave,
  children,
}: FormWrapperProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const deleteAutoSaveRef = useRef(() => { });

  const [formData, setFormData] = useState(defaultFormData);
  const [previousFormData, setPreviousFormData] = useState<Record<string, any> | null>(null);

  useAsyncEffect(async () => {
    if (id) {
      try {
        const res = await dataProvider.getOne!('', { id });
        const data = res.data;

        setFormData(data);
        setPreviousFormData(data);
      } catch (e) {
        notify('Не удалось получить данные', {
          type: 'error',
          autoHideDuration: 6000,
        });
      }
    }
  }, []);

  const defaultAfterSave: CustomFormAfterSaveCallback = (data, isWithExit) => {
    if (!data) {
      notify('Не удалось сохранить', {
        type: 'error',
        autoHideDuration: 6000,
      });

      return;
    }

    notify('Сохранено', {
      type: 'success',
      autoHideDuration: 3000,
    });

    if (isWithExit && redirectCollection) {
      redirect(`/${redirectCollection}`);
    }
  };

  const saveData = async (isWithExit: boolean) => {
    const callback = afterSave || defaultAfterSave;

    try {
      let result;

      if (previousFormData) {
        result = await dataProvider.update!('', {
          data: formData,
          previousData: { ...previousFormData, id: String(id) },
          id: previousFormData.id,
        });
      } else {
        delete formData.id; // На всякий случай, т.к. Parse не даёт создать объект у которого в attributes есть поле id

        result = await dataProvider.create!('', { data: formData });
      }

      deleteAutoSaveRef.current();

      setPreviousFormData(formData);

      callback(result.data, isWithExit);
    } catch (e) {
      callback(null, isWithExit);
    }
  };

  return (
    <CustomFormProvider
      formData={formData}
      setFormData={setFormData}
      validators={validators}
    >
      <CustomFormContainer flat={flat}>
        {children}

        <Actions
          saveData={saveData}
          isEdit={!!id}
        />
      </CustomFormContainer>
    </CustomFormProvider>
  );
};

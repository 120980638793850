/**
 * Для работы с вложенными полями у объекта.
 *
 * В качестве path выступает строка где поля разделены точками
 *
 * @example
 * let obj1 = {}
 * // obj1 станет:
 * // { data: { value: 5 } }
 * Nested.set(obj1, 'data.value', 5);
 *
 * // вернёт 5
 * Nested.get(obj1, 'data.value')
 *
 * // Вернёт undefined
 * Nested.get(obj1, 'someField.aaa.bbb.ddd')
 */
export namespace Nested {
  export const get = (obj: Record<string, any>, path: string): any => path
    .split('.')
    .reduce((acc, field) => {
      if (acc === undefined) {
        return undefined;
      }

      return acc[field];
    }, obj);

  export const set = (obj: Record<string, any>, path: string, value: any): Record<string, any> => {
    const fields = path.split('.');

    const objCopy = JSON.parse(JSON.stringify(obj));
    let currentObj = objCopy;

    fields.forEach((field, index) => {
      if (index === fields.length - 1) {
        currentObj[field] = value;
      } else {
        // Проверка на null чисто на всякий случай, по идее его никто сеттить не будет
        if (typeof currentObj[field] === 'object' && currentObj[field] !== null) {
          currentObj = currentObj[field];
        } else {
          const newObj = Object.create(null);

          currentObj[field] = newObj;
          currentObj = newObj;
        }
      }
    });

    return objCopy;
  };
}

import React from "react";

import { useNotify } from "react-admin";

import useAsyncEffect from "@hooks/useAsyncEffect";

import { generateQuery, request } from "@utils";
import { Endpoint } from "@constants";
import { DataGrid, GridColDef, GridSortItem } from "@mui/x-data-grid";
import { IncrementDaysButton } from "./IncrementDaysButton";

type HistoryProps = {
  productId: string;
};

const columns: GridColDef[] = [
  { field: 'daysOfPremium', headerName: 'Дней', width: 150 },
  { field: 'createdAt', headerName: 'Дата', width: 300 },
];

export const History: React.FC<HistoryProps> = ({ productId }) => {
  const [loading, setLoading] = React.useState(false);
  const [history, setHistory] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState(0);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = React.useState<GridSortItem>();

  const notify = useNotify();

  const loadHistory = async () => {
    setLoading(true);

    const { page, pageSize } = paginationModel;

    const pagination = { page: page + 1, perPage: pageSize };
    const sort = sortModel ? { field: sortModel.field || 'createdAt', order: sortModel.sort || 'desc' } : {} as any;

    const query = generateQuery({ pagination, sort, filter: { q: productId } });

    const result = await request<any>(`${Endpoint.ProductPremiumDaysHistory}${query}`, 'GET');

    if (!result) {
      notify('Не удалось получить заказы пользователя', 'error');
      setLoading(false);
      return;
    }

    setHistory(result.entities);
    setTotal(result.total);
    setLoading(false);
  };

  useAsyncEffect(loadHistory, [paginationModel, sortModel]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <IncrementDaysButton productId={productId} />

      <DataGrid
        loading={loading}
        sortModel={sortModel ? [sortModel] : undefined}
        onSortModelChange={(model) => {
          setSortModel(model[0]);
        }}
        rows={history}
        rowCount={total}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(model, details) => {
          if (details.reason) {
            setPaginationModel(model);
          }
        }}
        paginationMode='server'
        pageSizeOptions={[10, 25, 50]}
      />
    </div>
  );
};

import React from 'react';

import { EditProps } from 'react-admin';

import { ProductForm } from '@components/forms';

export const ProductEdit: React.FC<EditProps> = ({ id }) => {
  return (
    <ProductForm id={id} />
  );
};

import { FC } from 'react';

import { colors, Typography } from '@mui/material';

interface CustomFormErrorTextProps {
  errorText: string | undefined,
}

export const CustomFormErrorText: FC<CustomFormErrorTextProps> = ({
  errorText,
}) => {
  if (errorText === undefined) {
    return <></>;
  }

  return (
    <Typography
      variant='subtitle2'
      color={colors.red[700]}
    >
      {errorText}
    </Typography>
  );
};

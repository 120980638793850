import { FC } from 'react';
import { CardActions, CardActionsProps, SxProps } from '@mui/material';

export const defaultCustomFormActionsStyles: SxProps = {
  backgroundColor: '#f5f5f5',
};

type CustomFormActionsProps = CardActionsProps;

export const CustomFormActions: FC<CustomFormActionsProps> = (props: CustomFormActionsProps) => {
  const {
    sx = defaultCustomFormActionsStyles,
    children,
    ...otherProps
  } = props;

  return (
    <CardActions
      {...otherProps}
      sx={sx}
    >
      {children}
    </CardActions>
  );
};

import { promocodeSources } from '@constants';
import { DiscountType } from '@enum';
import { CustomFormValidator, CustomFormValidators } from '@interfaces/validator';

const required: CustomFormValidator = (value) => !!value || 'Обязательное поле';
const startDate: CustomFormValidator = (value, form) => {
  if (!value) {
    return 'Обязательное поле';
  }

  if (value > form.endDate) {
    return 'Дата начала должна быть меньше даты окончания';
  }

  return;
};
const endDate: CustomFormValidator = (value, form) => {
  if (!value) {
    return 'Обязательное поле';
  }

  if (value < form.startDate) {
    return 'Дата конца должна быть больше даты начала';
  }

  return;
};
const discount: CustomFormValidator = (value, form) => {
  if (!value) {
    return 'Скидка не может быть 0';
  }

  if (value < 0) {
    return 'Скидка не может быть меньше 0';
  }

  if (value > 100 && form.type === DiscountType.percent) {
    return 'Скидка не может быть больше 100%';
  }

  return;
};

export const promocodeValidators: CustomFormValidators = {
  [promocodeSources.discount]: discount,
  [promocodeSources.title]: required,
  [promocodeSources.code]: required,
  [promocodeSources.startDate]: startDate,
  [promocodeSources.endDate]: endDate,
};

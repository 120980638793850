export enum CollectionRus {
  User = 'Пользователи',
  Product = 'Продукты',
  Order = 'Заказы',

  ProductPremiumDaysHistory = 'История премиум дней продукта',

  Promocode = 'Промокоды',
  Referral = 'Реферальные ссылки',
}

export enum Collection {
  User = 'user',
  Product = 'product',
  Order = 'order',

  ProductPremiumDaysHistory = 'productPremiumDaysHistory',

  Promocode = 'promocode',
  Referral = 'referral',
}

export const MapResourceObject: Record<CollectionRus, Collection> = {
  [CollectionRus.User]: Collection.User,
  [CollectionRus.Product]: Collection.Product,
  [CollectionRus.Order]: Collection.Order,
  [CollectionRus.Referral]: Collection.Referral,
  [CollectionRus.Promocode]: Collection.Promocode,
  [CollectionRus.ProductPremiumDaysHistory]: Collection.ProductPremiumDaysHistory,
};

import { FC, memo, useCallback } from "react";
import { Box, Switch, Typography } from "@mui/material";

import { CustomFormErrorText } from '@customForms/components';
import { CustomFormFieldProps, wrapField } from '@customForms/hoc';

interface CustomBooleanInputProps extends CustomFormFieldProps<boolean> {
  label?: string;
  disabled?: boolean;
};

const Component: FC<CustomBooleanInputProps> = memo(({
  label = '',
  disabled = false,
  value,
  errorText,
  onChange,
}) => {
  const onChangeHandler = useCallback((_e: any, newValue: any) => {
    onChange(newValue);
  }, [onChange]);

  return (
    <Box display="flex">
      <Switch
        disabled={disabled}
        checked={value}
        onChange={onChangeHandler}
      />

      <Typography alignSelf="center">
        {label}
      </Typography>

      <CustomFormErrorText errorText={errorText} />
    </Box>
  );
});

export const CustomBooleanInput = wrapField(Component, 'BooleanInput');

import React from 'react';

import { EditProps } from 'react-admin';

import { ReferralForm } from '@components/forms';

export const ReferralEdit: React.FC<EditProps> = ({ id }) => {
  return (
    <ReferralForm id={id} />
  );
};

import { FC, memo, useCallback } from "react";
import { Box, Select, InputLabel, SxProps, Theme, MenuItem, SelectProps as MUISelectProps } from "@mui/material";
import { CustomFormErrorText } from "@customForms/components";
import { CustomFormFieldProps, wrapField } from '@customForms/hoc';

type SelectProps = Omit<MUISelectProps, 'value' | 'onChange'>;

type Choice = Record<string, any>;

interface CustomSelectInputProps extends SelectProps, CustomFormFieldProps {
  label: string;
  choices: Choice[];
  optionText: string;
  optionValue: string;
  sx?: SxProps<Theme>;
  allowEmpty?: boolean;
};

export const CustomSelectInputBase: FC<CustomSelectInputProps> = memo(({
  sx,
  value,
  onChange,
  errorText,
  label,
  choices,
  optionText,
  optionValue,
  allowEmpty,
  ...otherProps
}) => {
  const onChangeHandler = useCallback((e: any) => {
    const rawValue = e.target.value;

    onChange(rawValue);
  }, [onChange]);

  return (
    <Box sx={sx}>
      <InputLabel>{label}</InputLabel>

      <Select
        {...otherProps}
        defaultValue={null}
        value={value}
        onChange={onChangeHandler}
        error={!!errorText}
      >
        {allowEmpty && (
          <MenuItem value="">
            <em>&nbsp;</em>
          </MenuItem>
        )}

        {choices.map((choice) => (
          <MenuItem value={choice[optionValue]} key={choice[optionValue]}>{choice[optionText]}</MenuItem>
        ))}
      </Select>

      <CustomFormErrorText errorText={errorText} />
    </Box>
  );
});

export const CustomSelectInput = wrapField(CustomSelectInputBase, 'SelectInput');

import { userSources } from '@constants';

import { ListProps } from '@mui/material';

import { Datagrid, DateField, List, TextField, SearchInput } from 'react-admin';

const userFilters = [
  <SearchInput source="q" alwaysOn />,
];

export const UserList = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={userFilters}
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <TextField
          label="Id"
          source={userSources.id}
        />

        <TextField
          label="Бонусны баланс"
          source={userSources.bonusBalance}
        />

        <DateField
          label="Зарегистрирован"
          source="createdAt"
        />

        <DateField
          label="Изменён"
          source="updatedAt"
        />
      </Datagrid>
    </List>
  );
};

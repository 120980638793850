
import { UserForm } from '@components/forms';

import { EditProps } from 'react-admin';

export const UserEdit = ({ id }: EditProps) => {
  return (
    <UserForm id={id} />
  );
};

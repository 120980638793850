export enum Endpoint {
  User = '/users',
  Product = '/products',
  Order = '/orders',
  Promocode = '/promocodes',
  Referral = '/referrals',
  ProductPremiumDaysHistory = '/product-premium-days-history',
}

export enum From {
  RAPTOR = 'raptor',
  LINKER = 'linker'
}

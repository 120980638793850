import { From } from "./common";

export const productFromChoices = [
  {
    value: From.RAPTOR,
    label: 'Raptor bot',
  },
  {
    value: From.LINKER,
    label: 'Linker bot',
  },
];

import { Admin, Resource } from 'react-admin';

import { CollectionRus } from '@enum';

import { localeAdapter } from '@utils';

import { authProvider, dataProvider } from './dataProviders';

import {
  UserList,
  UserEdit,

  OrderList,
  OrderView,

  ReferralList,
  ReferralCreate,
  ReferralEdit,

  ProductList,
  ProductCreate,
  ProductEdit,

  PromocodeList,
  PromocodeEdit,
  PromocodeCreate,
} from './views';

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={localeAdapter}
  >
    <Resource
      name={CollectionRus.User}
      list={UserList}
      edit={UserEdit}
    />

    <Resource
      name={CollectionRus.Promocode}
      list={PromocodeList}
      edit={PromocodeEdit}
      create={PromocodeCreate}
    />

    <Resource
      name={CollectionRus.Product}
      list={ProductList}
      create={ProductCreate}
      edit={ProductEdit}
    />

    <Resource
      name={CollectionRus.Order}
      list={OrderList}
      show={OrderView}
    />

    <Resource
      name={CollectionRus.Referral}
      list={ReferralList}
      create={ReferralCreate}
      edit={ReferralEdit}
    />
  </Admin>
);

export default App;

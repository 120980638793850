import { CustomFormFieldProps, wrapField } from '@customForms/hoc';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { FC, useCallback } from 'react';

const isOptionEqualToValue = () => true;
// исправляет баг с тем что значения в полях пересекаются с label
// он просто всегда держит его вверху
const inputLabelProps = { shrink: true };

type ACProps = Omit<
  AutocompleteProps<any, false, false, undefined>,
  | 'value'
  | 'onChange'
  | 'disablePortal'
  | 'options'
  | 'disableClearable'
  | 'getOptionLabel'
  | 'isOptionEqualToValue'
  | 'renderInput'
  | 'multiline'
  | 'freeSolo'
>;

interface CustomAutocompleteInputProps extends ACProps, CustomFormFieldProps<string | undefined> {
  label: string,
  clearable?: boolean,
  options: Record<string, any>[],
  /** Функция для получения текста, который будет отображаться для каждого объекта */
  getOptionLabel: (option: any | undefined) => string,
  /** Поле объекта option, которое считать его идентификатором. По умолчанию - id */
  optionIdentifierKey?: string,
  /**
   * Вызывается ВМЕСТО onChange, но передаёт не значение поля optionIdentifierKey,
   * а сам объект option. Позволяет выполнить дополнительные действия после выбора option.
   */
  onOptionSelect?: (option: any | null) => void,
}

/**
 * ! value это не сам объект option, а значение его поля по ключу optionIdentifierKey (по умолчанию - 'id')
 * ! onChange принимает в себя не объект option, а значение его поля по ключу optionIdentifierKey
 */
export const CustomAutocompleteInputBase: FC<CustomAutocompleteInputProps> = ({
  label,
  clearable = true,
  errorText,
  value,
  onChange,
  options,
  getOptionLabel,
  optionIdentifierKey = 'id',
  onOptionSelect,
  ...otherProps
}) => {
  const handleChange = useCallback((_: any, newValue: Record<string, any> | null) => {
    if (onOptionSelect) {
      onOptionSelect(newValue);
      return;
    }

    onChange(newValue?.[optionIdentifierKey]);
  }, [onChange, onOptionSelect]);

  const elements = Array.isArray(options) ? options : [];

  const selectedElement = elements.find(el => el?.[optionIdentifierKey] === value) ?? null;

  return (
    <Autocomplete
      {...otherProps}
      value={selectedElement}
      onChange={handleChange}
      disablePortal
      // @ts-ignore
      disableClearable={!clearable}
      options={elements}
      getOptionLabel={getOptionLabel}
      // helped to remove warning
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={label}
          error={!!errorText}
          helperText={errorText}
          InputLabelProps={inputLabelProps}
        />
      )}
    />
  );
};

export const CustomAutocompleteInput = wrapField(CustomAutocompleteInputBase, 'AutocompleteInput');

import { ComponentType } from 'react';

import { useCustomFormFieldValidation, useCustomFormField } from '../context';

export interface CustomFormFieldProps<T = any> {
  value: T | undefined,
  onChange: (data: T) => void,
  errorText?: string,
}

type KeysToOmit = keyof CustomFormFieldProps;

export type CustomFormWrappedFieldProps<T = any> = Omit<CustomFormFieldProps<T>, KeysToOmit> & { fieldKey: string; };

/**
 * Предоставляет компоненту value, обработчик onChange, errorText(результат валидации).
 * Всё берётся из CustomFormProvider
 */
export const wrapField = <T extends CustomFormFieldProps = CustomFormFieldProps>(
  Component: ComponentType<T>,
  fieldName: string = 'Component'
) => {
  const WrappedField = (props: Omit<T, KeysToOmit> & { fieldKey: string; }) => {
    const [value, onChange] = useCustomFormField(props.fieldKey);
    const errorText = useCustomFormFieldValidation(props.fieldKey);

    return (
      <Component
        {...props as any}
        errorText={errorText}
        value={value}
        onChange={onChange}
      />
    );
  };

  WrappedField.displayName = `wrappedField(${fieldName})`;

  return WrappedField;
};

import { Endpoint } from "@constants";
import { ReferralList, SerializedReferral } from "@interfaces/entities";
import { DataProvider } from "@interfaces/provider";
import { generateQuery, request } from "@utils";

const endpoint = Endpoint.Referral;

export const referralDataProvider: DataProvider<SerializedReferral> = {
  getList: async (_, data) => {
    const query = generateQuery(data);

    const result = await request<ReferralList>(`${endpoint}${query}`, 'GET');

    if (!result) {
      return {
        data: [],
        total: 0,
      };
    }

    const { entities, total } = result;

    return {
      total,
      data: entities,
    };
  },
  getOne: async (_, { id }) => {
    const result = await request(`${endpoint}/${id}`, 'GET');

    return {
      data: result,
    };
  },
  create: async (_, { data }) => {
    const newData = {
      ...data,
    };
    const body = JSON.stringify(newData);

    const result = await request(endpoint, 'POST', body);

    return {
      data: result,
    };
  },
  update: async (_, { id, data }) => {
    const newData: any = {
      ...data,
    };

    delete newData.id;
    const body = JSON.stringify(newData);

    const result = await request(`${endpoint}/${id}`, 'PATCH', body);

    return {
      data: result,
    };
  },
};

import React from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { Endpoint } from "@constants";
import { request } from "@utils";
import { useNotify } from "react-admin";

type IncrementDaysButtonProps = {
  productId: string;
};

export const IncrementDaysButton: React.FC<IncrementDaysButtonProps> = ({
  productId,
}) => {
  const [text, setText] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const notify = useNotify();

  const addPremiumDays = async () => {
    const days = Number(text);

    if (!days || (days && Number.isNaN(Number(days)))) {
      notify('Количество дней не число', 'error');
      return;
    }

    const body = JSON.stringify({ days });

    const result = await request(`${Endpoint.Product}/incrementDaysOfPremium/${productId}`, 'PATCH', body);

    if (!result) {
      notify('Не удалось добавить дни премиума', 'error');
      return;
    }

    setOpen(false);
    window.location.reload();
  };

  return (
    <Box>
      <Button variant='contained' onClick={() => setOpen(true)}>Добавить дни премиума</Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {"Добавление дней премиума для продукта"}
        </DialogTitle>

        <DialogContent>
          <TextField type='number' required value={text} onChange={(e) => setText(e.target.value)} />
        </DialogContent>

        <DialogActions>
          <Button variant='contained' onClick={() => setOpen(false)}>Отмена</Button>

          <Button variant='contained' onClick={addPremiumDays} autoFocus>
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

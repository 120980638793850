import { promocodeDiscountTypeChoices, promocodeSources } from '@constants';

import { ListProps } from '@mui/material';

import { Datagrid, DateField, DeleteButton, List, SelectField, TextField } from 'react-admin';

export const PromocodeList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <TextField
          label="Описание промокода"
          source={promocodeSources.title}
        />

        <TextField
          label="Промокод"
          source={promocodeSources.code}
        />

        <TextField
          label="Скидка"
          source={promocodeSources.discount}
        />

        <TextField
          label="Количество использований"
          source={promocodeSources.countOfUse}
        />

        <SelectField
          label="Тип скидки"
          choices={promocodeDiscountTypeChoices}
          optionText="label"
          optionValue="type"
          source={promocodeSources.type}
        />

        <DateField
          label="Дата начала"
          source={promocodeSources.startDate}
        />

        <DateField
          label="Дата конца"
          source={promocodeSources.endDate}
        />

        <DeleteButton label="" />
      </Datagrid>
    </List>
  );
};

import { productSources } from '@constants';
import { CustomFormValidator, CustomFormValidators } from '@interfaces/validator';

const required: CustomFormValidator = (value) => !!value || 'Обязательное поле';

export const productValidators: CustomFormValidators = {
  [productSources.name]: required,
  [productSources.userId]: required,
  [productSources.from]: required,
};

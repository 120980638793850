/* eslint-disable no-console */

import { getSessionToken } from "./get-session-token";

const serverUrl = process.env.REACT_APP_SERVER_URL;

type LoginParams = {
  username: string;
  password: string;
};

export const login = async (params: LoginParams) => {
  try {
    const res = await fetch(`${serverUrl}/admen/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    });
    const data = await res.json();

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const request = async <T = any>(url: string, method: string, body?: any): Promise<T | null> => {
  const token = getSessionToken();

  try {
    const res = await fetch(`${serverUrl}${url}`, {
      method,
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body,
    });
    const data = await res.json();

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

import { FC } from 'react';
import { CustomFormActions } from '@customForms/components';
import { Button } from '@mui/material';
import { useLoadingState } from '@hooks/useLoadingState';
import { useCustomFormValidation } from '@customForms/context';

interface ActionsProps {
  saveData: (isWithExit: boolean) => Promise<void>,
  isEdit: boolean,
}

export const Actions: FC<ActionsProps> = ({
  saveData,
  isEdit = false,
}) => {
  const { isLoading, startLoading, endLoading } = useLoadingState(false);
  const isFormValid = useCustomFormValidation();

  const saveWithLoading = async (isWithExit: boolean) => {
    if (isLoading || !isFormValid) return;

    startLoading();

    await saveData(isWithExit);

    endLoading();
  };

  const saveAndExit = () => saveWithLoading(true);
  const justSave = () => saveWithLoading(false);

  return (
    <CustomFormActions>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        disabled={!isFormValid || isLoading}
        onClick={saveAndExit}
      >
        Сохранить и выйти
      </Button>

      {isEdit && (
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="success"
          disabled={!isFormValid || isLoading}
          onClick={justSave}
        >
          Сохранить
        </Button>
      )}
    </CustomFormActions>
  );
};

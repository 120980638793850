import { productFromChoices, productSources } from '@constants';

import { ListProps } from '@mui/material';

import { Datagrid, List, NumberField, SelectField, TextField, SearchInput } from 'react-admin';

const productFilters = [
  <SearchInput source="q" alwaysOn />,
];

export const ProductList = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={productFilters}
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <TextField
          label="Название продукта"
          source={productSources.name}
        />

        <TextField
          label="id пользователя"
          source={productSources.userId}
        />

        <NumberField
          label="Дни премиума"
          source={productSources.remainingDaysOfPremium}
        />

        <SelectField
          label="From"
          choices={productFromChoices}
          optionText="label"
          optionValue="value"
          source={productSources.from}
        />
      </Datagrid>
    </List>
  );
};

import { DiscountType } from "@enum";

export const promocodeDiscountTypeChoices = [
  {
    type: DiscountType.percent,
    label: 'Процентная',
  },
  {
    type: DiscountType.fixed,
    label: 'Фиксированная',
  },
];

import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useContext } from 'react';
import { CustomFormOnChange, CustomFormValidators } from '@interfaces/validator';
import { Nested } from '@utils';

type CustomFormData = Record<string, any>;

type SetFormData<T = any> = Dispatch<SetStateAction<T>>;

export const CustomFormDataContext = createContext<CustomFormData>({});
export const CustomFormValidatorsContext = createContext<CustomFormValidators>({});
export const CustomFormOnChangeCallbackContext = createContext<CustomFormOnChange>(() => { });
export const CustomFormSetFormData = createContext<SetFormData<CustomFormData>>(() => { });

interface CustomFormProviderProps extends PropsWithChildren<{}> {
  formData: CustomFormData,
  setFormData: SetFormData,
  validators: CustomFormValidators,
}

export const CustomFormProvider: FC<CustomFormProviderProps> = ({
  formData,
  setFormData,
  validators,
  children,
}) => {
  const onChange = useCallback<CustomFormOnChange>((key, value) => {
    if (key.split('.').length > 1) {
      setFormData((prevData: CustomFormData) => {
        const newData = Nested.set(prevData, key, value);

        return newData;
      });

      return;
    }

    setFormData((prevData: CustomFormData) => ({
      ...prevData,
      [key]: value,
    }));
  }, []);

  return (
    <CustomFormSetFormData.Provider value={setFormData}>
      <CustomFormOnChangeCallbackContext.Provider value={onChange}>
        <CustomFormValidatorsContext.Provider value={validators}>
          <CustomFormDataContext.Provider value={formData}>
            {children}
          </CustomFormDataContext.Provider>
        </CustomFormValidatorsContext.Provider>
      </CustomFormOnChangeCallbackContext.Provider>
    </CustomFormSetFormData.Provider>
  );
};

export const useCustomFormSetData = <T extends CustomFormData = CustomFormData>(): SetFormData<T> => {
  const setFormData = useContext(CustomFormSetFormData) as SetFormData<T>;

  return setFormData;
};

export const useCustomFormValidators = () => {
  const validators = useContext(CustomFormValidatorsContext);

  return validators;
};

export const useCustomFormData = <T extends CustomFormData = CustomFormData>(): T => {
  const formData = useContext(CustomFormDataContext);

  return formData as T;
};

export const useCustomFormOnChangeCallback = () => {
  const onChange = useContext(CustomFormOnChangeCallbackContext);

  return onChange;
};

export const useCustomFormFieldValue = <T extends any = any>(fieldKey: string): T | undefined => {
  const formData = useContext(CustomFormDataContext);

  return Nested.get(formData, fieldKey);
};

export const useCustomFormField = <T extends any = any>(fieldKey: string): [T | undefined, (data: T) => void] => {
  const value = useCustomFormFieldValue<T>(fieldKey);
  const onChange = useCustomFormOnChangeCallback();

  const changeValue = useCallback((data: T) => {
    onChange(fieldKey, data);
  }, [fieldKey, onChange]);

  return [value, changeValue];
};

/** @returns Валидна ли форма? true - да, false - нет */
export const useCustomFormValidation = () => {
  const formData = useCustomFormData();
  const validators = useCustomFormValidators();

  const keysToValidate = Object.keys(validators);

  return keysToValidate.every(key => {
    const validator = validators[key];

    const value = Nested.get(formData, key);
    const validateResult = validator?.(value, formData);

    return typeof validateResult !== 'string';
  });
};

/**
 * @returns
 * errorText: string | undefined
 */
export const useCustomFormFieldValidation = (fieldKey: string): string | undefined => {
  const formData = useContext(CustomFormDataContext);
  const validators = useContext(CustomFormValidatorsContext);

  const validator = validators[fieldKey];

  const value = Nested.get(formData, fieldKey);

  const validationResult = validator?.(value, formData);
  const isInvalid = typeof validationResult === 'string';
  const errorText = isInvalid ? validationResult : undefined;

  return errorText;
};

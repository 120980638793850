import { Endpoint } from "@constants";
import { generateQuery, request } from "@utils";
import { DataProvider } from "@interfaces/provider";
import { PromocodeList, SerializedPromocode } from "@interfaces/entities";

const endpoint = Endpoint.Promocode;

export const promocodeDataProvider: DataProvider<SerializedPromocode> = {
  getList: async (_, data) => {
    const query = generateQuery(data);

    const result = await request<PromocodeList>(`${endpoint}${query}`, 'GET');

    if (!result) {
      return {
        data: [],
        total: 0,
      };
    }

    const { entities, total } = result;

    return {
      total,
      data: entities,
    };
  },
  getOne: async (_, { id }) => {
    const result = await request(`${endpoint}/${id}`, 'GET');

    return {
      data: result,
    };
  },
  create: async (_, { data }) => {
    const newData = {
      ...data,
      startDate: new Date(data.startDate).getTime(),
      endDate: new Date(data.endDate).getTime(),
    };
    const body = JSON.stringify(newData);

    const result = await request(endpoint, 'POST', body);

    return {
      data: result,
    };
  },
  update: async (_, { id, data }) => {
    const newData: any = {
      ...data,
      startDate: new Date(data.startDate).getTime(),
      endDate: new Date(data.endDate).getTime(),
    };
    delete newData.id;
    const body = JSON.stringify(newData);

    const result = await request(`${endpoint}/${id}`, 'PATCH', body);

    return {
      data: result,
    };
  },
};

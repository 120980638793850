import { FC, memo } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  colors,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import { CustomFormErrorText, CustomFormFieldLabel } from '@customForms/components';
import { CustomFormFieldProps, wrapField } from '@customForms/hoc';

interface CustomFormSimpleArrayInputProps extends CustomFormFieldProps<any[]> {
  title: string;
};

const Component: FC<CustomFormSimpleArrayInputProps> = memo(({
  title,
  value,
  onChange: setSelectedValues,
  errorText,
}) => {
  const selectedValues = Array.isArray(value) ? value : [];

  const addElement = () => {
    setSelectedValues([...selectedValues, '']);
  };

  const onRemove = (idx: number) => {
    const newArray = Array.from(selectedValues);

    newArray.splice(idx, 1);

    setSelectedValues([...newArray]);
  };

  const onChange = (value: string, idx: number) => {
    const newValue: any[] = selectedValues.map(
      (selectedValue, index) => {
        if (index === idx) {
          return value;
        }

        return selectedValue;
      }
    );

    setSelectedValues([...newValue]);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <CustomFormFieldLabel text={title} />

      {selectedValues.map((value, i) => {
        return (
          <Box
            key={i}
            display="flex"
            justifyContent="flex-start"
          >
            <Typography mt="10px" variant="h6" component="div">
              {i + 1}
            </Typography>

            <Box ml={2} display="flex" flexDirection="column">
              <TextField
                sx={{ width: 360 }}
                value={value}
                error={!value}
                onChange={(e) => {
                  onChange(e.target.value, i);
                }}
                // исправляет баг с тем что значения в полях пересекаются с label
                // он просто всегда держит его вверху
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {!value && (
                <Typography
                  variant='subtitle2'
                  color={colors.red[700]}
                >
                  Обязательно для заполнения
                </Typography>
              )}
            </Box>

            <Box mt={1} ml={1}>
              <IconButton
                size="medium"
                onClick={() => onRemove(i)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        );
      })}

      <CustomFormErrorText errorText={errorText} />

      <Box mt={1}>
        <Button
          onClick={addElement}
          variant="contained"
        >
          Добавить
        </Button>
      </Box>
    </Box>
  );
});

export const CustomSimpleArrayInput = wrapField(Component, 'SimpleArrayInput');

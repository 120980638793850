import { useState } from 'react';

type Callback<ResultT> = () => Promise<ResultT> | ResultT;

export const useLoadingState = (initialValue = false) => {
  const [isLoading, setIsLoading] = useState(initialValue);

  const startLoading = () => {
    setIsLoading(true);
  };

  const endLoading = () => {
    setIsLoading(false);
  };

  /**
   * @example
   * const result = await callWithLoading(getSomeListAsync)
   *
   * const result = await callWithLoading(async () => {
   *  return getSomeListWithFiltersAsync({ length: 5, ... })
   * })
   */
  const callWithLoading = async <CbResult>(callback: Callback<CbResult>): Promise<CbResult> => {
    startLoading();

    const result = await callback();

    endLoading();

    return result;
  };

  return {
    isLoading,
    startLoading,
    endLoading,
    callWithLoading,
  };
};

import { FC, memo } from "react";
import { Box, TextField, BaseTextFieldProps } from "@mui/material";
import { CustomFormErrorText } from '@customForms/components';
import { CustomFormFieldProps, wrapField } from '@customForms/hoc';

type TextFieldProps = Omit<BaseTextFieldProps, 'value' | 'onChange' | 'error'>;

interface CustomTextInputProps extends TextFieldProps, CustomFormFieldProps {
  type?: 'text' | 'number' | 'date';
};

const convertDate = (date: number) => new Date(date).toISOString().split('T')[0];

const normalizeValue = (rawValue: any, type: CustomTextInputProps['type']) => {
  switch (type) {
    case 'text': return String(rawValue || '');
    case 'number': return Number(rawValue) || 0;
    case 'date': {
      if (rawValue === undefined) {
        return '';
      }

      return convertDate(rawValue);
    }
  }
};

const InputLabelProps = { shrink: true };

export const CustomTextInputBase: FC<CustomTextInputProps> = memo(({
  sx,
  value: rawValue,
  onChange,
  type = 'text',
  errorText,
  ...otherProps
}) => {
  const value = normalizeValue(rawValue, type);

  const onChangeHandler = (e: any) => {
    const rawValue = e.target.value;
    const value = normalizeValue(rawValue, type);

    onChange(value);
  };

  return (
    <Box sx={sx}>
      <TextField
        {...otherProps}
        value={value}
        onChange={onChangeHandler}
        type={type}
        error={!!errorText}
        // исправляет баг с тем что значения в полях пересекаются с label
        // он просто всегда держит его вверху
        InputLabelProps={InputLabelProps}
      />

      <CustomFormErrorText errorText={errorText} />
    </Box>
  );
});

export const CustomTextInput = wrapField(CustomTextInputBase, 'TextInput');

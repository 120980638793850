import { LegacyDataProvider } from "react-admin";

import { Collection, CollectionRus, MapResourceObject } from "@enum";

import { DataProviderKeys, DataRequestedProviderKeys } from "@interfaces/common";

import { userDataProvider } from "./userDataProvider";
import { orderDataProvider } from "./orderDataProvider";
import { productDataProvider } from "./productDataProvider";
import { referralDataProvider } from "./referralDataProvider";
import { promocodeDataProvider } from "./promocodeDataProvider";

const dataProviders: Partial<Record<Collection, any>> = {
  [Collection.User]: userDataProvider,
  [Collection.Order]: orderDataProvider,
  [Collection.Product]: productDataProvider,
  [Collection.Referral]: referralDataProvider,
  [Collection.Promocode]: promocodeDataProvider,
};

const delegate = (name: DataRequestedProviderKeys, resourceRus: CollectionRus, params: any) => {
  const resource = MapResourceObject[resourceRus];

  const getNameFunction = (): DataProviderKeys => {
    switch (name) {
      case 'GET_LIST':
        return 'getList';
      case 'GET_ONE':
        return 'getOne';
      case 'GET_MANY':
        return 'getMany';
      case 'GET_MANY_REFERENCE':
        return 'getManyReference';
      case 'UPDATE':
        return 'update';
      case 'UPDATE_MANY':
        return 'updateMany';
      case 'CREATE':
        return 'create';
      case 'DELETE':
        return 'delete';
      case 'DELETE_MANY':
        return 'deleteMany';;
      default:
        return 'getList';
    }
  };

  const dataProvider = dataProviders[resource];

  if (dataProvider) {
    const handledFunction = dataProvider[getNameFunction()];

    if (handledFunction) {
      return handledFunction(resource, params);
    }
  }
  return {
    data: [],
  };
};

export const dataProvider: LegacyDataProvider = delegate as LegacyDataProvider;
export { authProvider } from './authProvider';

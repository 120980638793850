import polyglotI18nProvider from 'ra-i18n-polyglot';
// @ts-ignore
import russianMessages from 'ra-language-russian';
import { I18nProvider } from 'react-admin';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

export const localeAdapter: I18nProvider = {
  ...i18nProvider,
  translate: (key: string, option: any) => {
    if (key.includes('resources.')) {
      return key.split('.')[1];
    }
    return i18nProvider.translate(key, option);
  },
};

import { FC } from 'react';
import { CardContent, CardContentProps, SxProps } from '@mui/material';

interface CustomFormContentProps extends CardContentProps { }

export const defaultCustomFormContentStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

export const CustomFormContent: FC<CustomFormContentProps> = ({
  sx = defaultCustomFormContentStyle,
  children,
  ...otherProps
}) => {
  return (
    <CardContent
      {...otherProps}
      sx={sx}
    >
      {children}
    </CardContent>
  );
};

export const tokenStorageKey = 'access-token'

export const getSessionToken = () => {
  const sessionToken = localStorage.getItem(tokenStorageKey) || '';

  if (!sessionToken) {
    // eslint-disable-next-line no-console
    console.log('Some problems with get session token');
  }

  return sessionToken;
};

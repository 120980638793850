export enum YookassaPaymentStatus {
  Pending = 'pending',
  WaitingForCapture = 'waiting_for_capture',
  Succeeded = 'succeeded',
  Canceled = 'canceled',
}

export enum Period {
  MONTH = 30,
  QUARTER = 90,
  HALF_YEAR = 180,
  YEAR = 365,
}

export enum CloudKassirReceiptType {
  Income = 'Income',
  Refund = 'IncomeReturn',
}

export enum YookassaPaymentMethod {
  BankCard = 'bank_card',
  SberPay = 'sberbank',
  QIWI = 'qiwi',
  YooMoney = 'yoo_money',
}

export const orderKassaStatusChoices = [
  {
    value: YookassaPaymentStatus.Canceled,
    label: 'Отменён',
  },
  {
    value: YookassaPaymentStatus.Pending,
    label: 'Ожидание оплаты',
  },
  {
    value: YookassaPaymentStatus.Succeeded,
    label: 'Успешный',
  },
];

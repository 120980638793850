import { productFromChoices } from '@constants';

import { ListProps } from '@mui/material';

import { Datagrid, DeleteButton, EditButton, List, SelectField, TextField } from 'react-admin';
import { referralSources } from 'src/constants/model-sources/referral';

export const ReferralList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <TextField
          label="Приглашён"
          source={referralSources.invitedBy}
        />

        <TextField
          label="Пользователь"
          source={referralSources.userId}
        />

        <SelectField
          label="From"
          choices={productFromChoices}
          optionText="label"
          optionValue="value"
          source={referralSources.from}
        />

        <TextField
          label="Бонус"
          source={referralSources.bonus}
        />

        <TextField
          label="Первоначальный бонус"
          source={referralSources.firstBonus}
        />

        <TextField
          label="Всего начислено бонусов"
          source={referralSources.total}
        />

        <EditButton label='' />

        <DeleteButton label='' />
      </Datagrid>
    </List>
  );
};

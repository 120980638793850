import { CustomFormValidator, CustomFormValidators } from '@interfaces/validator';
import { referralSources } from 'src/constants/model-sources/referral';

const required: CustomFormValidator = (value) => !!value || 'Обязательное поле';

const bonus: CustomFormValidator = (value) => value >= 0 && value <= 100;

export const referralValidators: CustomFormValidators = {
  [referralSources.userId]: required,
  [referralSources.invitedBy]: required,
  [referralSources.bonus]: bonus,
  [referralSources.firstBonus]: bonus,
};

import { FC } from "react";

import { CustomFormContent } from '@customForms/components';
import { CustomSelectInput, CustomTextInput } from '@customForms/inputs';

import { FormWrapper } from "@components/common/FormWrapper";

import { promocodeDataProvider } from "@dataProviders/promocodeDataProvider";

import { promocodeValidators } from "@utils";
import { promocodeDiscountTypeChoices, promocodeSources } from "@constants";
import { CollectionRus, DiscountType } from "@enum";
import { CustomFormProps } from "@interfaces/validator";
import { Promocode } from "@interfaces/entities";

const defaultFormData: Partial<Promocode> = {
  title: '',
  code: '',
  discount: 0,
  type: DiscountType.percent,
};

export const PromocodeForm: FC<CustomFormProps> = (props) => {
  const { id } = props;

  return (
    <FormWrapper
      {...props}
      redirectCollection={CollectionRus.Promocode}
      defaultFormData={defaultFormData}
      dataProvider={promocodeDataProvider}
      validators={promocodeValidators}
    >
      <CustomFormContent>
        <CustomTextInput
          label="Описание промокода"
          fieldKey={promocodeSources.title}
          fullWidth
          required
        />

        <CustomTextInput
          label="Промокод"
          fieldKey={promocodeSources.code}
          required
        />

        <CustomSelectInput
          label="Тип скидки"
          choices={promocodeDiscountTypeChoices}
          optionText="label"
          optionValue="type"
          fieldKey={promocodeSources.type}
        />

        <CustomTextInput
          label="Скидка"
          fieldKey={promocodeSources.discount}
          type="number"
          required
        />

        <CustomTextInput
          label="Дата начала"
          fieldKey={promocodeSources.startDate}
          type="date"
          required
        />

        <CustomTextInput
          label="Дата окончания"
          fieldKey={promocodeSources.endDate}
          type="date"
          required
        />

        {!!id && (
          <CustomTextInput
            label="Количество использований"
            fieldKey={promocodeSources.countOfUse}
            disabled
            type="number"
          />
        )}
      </CustomFormContent>
    </FormWrapper>
  );
};

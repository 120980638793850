import { orderKassaStatusChoices, orderSources, productFromChoices } from '@constants';

import { ListProps } from '@mui/material';

import { Datagrid, DateField, List, NumberField, SelectField, TextField, SearchInput } from 'react-admin';

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
];

export const OrderList = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={orderFilters}
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <TextField
          label="paymentId"
          source={orderSources.paymentId}
        />

        <TextField
          label="id пользователя"
          source={orderSources.userId}
        />

        <SelectField
          label="From"
          choices={productFromChoices}
          optionText="label"
          optionValue="value"
          source={orderSources.from}
        />

        <SelectField
          label="Статус"
          choices={orderKassaStatusChoices}
          optionText="label"
          optionValue="value"
          source={orderSources.paymentStatus}
        />

        <NumberField
          label="Сумма"
          source={orderSources.price}
        />

        <DateField
          label="Дата создания"
          source="createdAt"
        />
      </Datagrid>
    </List>
  );
};

import { FC } from 'react';

import { EditProps } from 'react-admin';

import { PromocodeForm } from '@components/forms';

export const PromocodeEdit: FC<EditProps> = ({ id }) => {
  return (
    <PromocodeForm id={id} />
  );
};

export const orderSources = {
  id: 'id',
  userId: 'userId',
  from: 'from',
  paymentId: 'paymentId',
  paymentStatus: 'paymentStatus',
  price: 'price',
  method: 'method',
  period: 'period',
  promocodeId: 'promocodeId',
  useBonusBalance: 'useBonusBalance',
  remainingBonusBalance: 'remainingBonusBalance',
  productIds: 'productIds',
  paidDate: 'paidDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
};

import { GetListParams } from "react-admin";

export const generateQuery = ({ pagination, sort, filter }: GetListParams) => {
  const { page, perPage } = pagination;
  const { field, order } = sort;
  const { q: search } = filter;

  const query = [
    page ? `page=${page}` : undefined,
    perPage ? `limit=${perPage}` : undefined,
    field ? `sortField=${field}` : undefined,
    order ? `sortOrder=${order.toLowerCase()}` : undefined,
    search ? `search=${search}` : undefined,
  ]
    .filter(val => val)
    .join('&');

  return `?${query}`;
};

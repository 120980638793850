import { FC } from "react";

import { CustomFormContent } from '@customForms/components';
import { CustomTextInput } from '@customForms/inputs';

import { FormWrapper } from "@components/common/FormWrapper";

import { userSources } from "@constants";
import { CollectionRus } from "@enum";
import { CustomFormProps } from "@interfaces/validator";
import { User } from "@interfaces/entities";
import { userDataProvider } from "@dataProviders/userDataProvider";
import { UserOrders } from "./components";

const defaultFormData: Partial<User> = {
  username: '',
  firstName: '',
  secondName: '',
  bonusBalance: 0,
  totalPurchasesAmount: 0,
};

export const UserForm: FC<CustomFormProps> = (props) => {
  const { id } = props;

  return (
    <FormWrapper
      {...props}
      redirectCollection={CollectionRus.Product}
      defaultFormData={defaultFormData}
      dataProvider={userDataProvider}
      validators={{}}
    >
      <CustomFormContent>
        <CustomTextInput
          label="Id"
          fieldKey={userSources.id}
          fullWidth
          required
        />

        <CustomTextInput
          label="username"
          fieldKey={userSources.username}
        />

        <CustomTextInput
          label="Имя"
          fieldKey={userSources.firstName}
        />

        <CustomTextInput
          label="Фамилия"
          fieldKey={userSources.secondName}
        />

        <CustomTextInput
          label="Бонусный баланс"
          fieldKey={userSources.bonusBalance}
          type='number'
        />

        <CustomTextInput
          label="Цена всех покупок"
          fieldKey={userSources.totalPurchasesAmount}
          type='number'
          disabled
        />

        {!!id && (
          <UserOrders userId={id} />
        )}
      </CustomFormContent>
    </FormWrapper>
  );
};

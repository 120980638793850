import { FC } from "react";

import { CustomFormContent } from '@customForms/components';
import { CustomSelectInput, CustomTextInput } from '@customForms/inputs';

import { FormWrapper } from "@components/common/FormWrapper";

import { productFromChoices } from "@constants";
import { CollectionRus } from "@enum";
import { CustomFormProps } from "@interfaces/validator";
import { Referral } from "@interfaces/entities";
import { referralValidators } from "@utils";
import { referralDataProvider } from "@dataProviders/referralDataProvider";
import { referralSources } from "src/constants/model-sources/referral";

const defaultFormData: Partial<Referral> = {
  bonus: 30,
  firstBonus: 70,
  total: 0,
  userId: '',
  invitedBy: '',
};

export const ReferralForm: FC<CustomFormProps> = (props) => {
  const { id } = props;

  return (
    <FormWrapper
      {...props}
      redirectCollection={CollectionRus.Referral}
      defaultFormData={defaultFormData}
      dataProvider={referralDataProvider}
      validators={referralValidators}
    >
      <CustomFormContent>
        <CustomTextInput
          required
          label="userId"
          fieldKey={referralSources.userId}
        />

        <CustomTextInput
          required
          label="invitedBy"
          fieldKey={referralSources.invitedBy}
        />

        <CustomSelectInput
          label="From"
          choices={productFromChoices}
          optionText="label"
          optionValue="value"
          fieldKey={referralSources.from}
        />

        <CustomTextInput
          label="Бонус"
          fieldKey={referralSources.bonus}
          type="number"
        />

        <CustomTextInput
          label="Первый бонус"
          fieldKey={referralSources.firstBonus}
          type="number"
        />

        {!!id && (
          <CustomTextInput
            label="Всего получено бонусов"
            fieldKey={referralSources.total}
            disabled
            type="number"
          />
        )}
      </CustomFormContent>
    </FormWrapper>
  );
};

import React from "react";

import { Loading, useNotify } from "react-admin";

import { Endpoint } from "@constants";
import useAsyncEffect from "@hooks/useAsyncEffect";
import { OrderList, SerializedOrder } from "@interfaces/entities";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { request } from "@utils";

type UserOrdersProps = {
  userId: string;
};

export const UserOrders: React.FC<UserOrdersProps> = ({
  userId,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState<SerializedOrder[]>([]);

  const notify = useNotify();

  useAsyncEffect(async () => {
    setLoading(true);

    const result = await request<OrderList>(`${Endpoint.Order}?search=${userId}&limit=${1000}`, 'GET');

    if (!result) {
      notify('Не удалось получить заказы пользователя', 'error');
      setLoading(false);
      return;
    }

    setOrders(result.entities);
    setLoading(false);
  });

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'userId', headerName: 'userId', width: 150 },
    { field: 'paymentStatus', headerName: 'Статус', width: 150 },
    {
      field: 'productIds', headerName: 'Id продуктов', width: 300, renderCell: (data) => {
        const text = data.value.join(', ');

        const copy = () => navigator.clipboard.writeText(text);

        return <div onClick={copy}>
          {text}
        </div>
      }
    }
  ];

  if (loading) {
    return <Loading />;
  }

  if (!orders.length) {
    return null;
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={orders}
        columns={columns}
        // pageSize={5}
        pagination
        paginationMode="client"
        // paginationMode="server"
        pageSizeOptions={[10, 25, 50]}
      // rowsPerPageOptions={[5]}
      // checkboxSelection
      />
    </div>
  );
};
